import { Component } from '@angular/core';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-session-logout-warning',
  templateUrl: './session-logout-warning.component.html',
  styleUrls: ['./session-logout-warning.component.scss'],
})
export class SessionLogoutWarningComponent {
  remainingTime$ = this.sessionService.warningCounter;

  constructor(private sessionService: SessionService) {}
}

<div
*ngIf="remainingTime$ | async as remainingTime"
  id="logout-warning"
  class="bg-slate-950/50 fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center transition-opacity opacity-100 z-[100]">
  <div id="body" class="bg-white rounded overflow-clip transition-transform scale-100 w-96">
    <div class="px-4 py-4 flex items-center bg-gray-100">
      <h3 class="text-slate-800">Session inactive</h3>
    </div>
    <div class="flex flex-col px-4 py-8 gap-8">
      <div>Your session will expire in {{ remainingTime * 1000 | date : 'mm:ss':'UTC' }} due to inactivity.</div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import {
  Notification,
  NotificationService,
} from '../../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(public notificationService: NotificationService) {}

  getIconPath(toast: Notification) {
    if (toast.icon === 'connection-issue') {
      return '/assets/svgs/notification-wifi.svg';
    } else if (toast.type === 'neutral') {
      return '/assets/svgs/notification-neutral.svg';
    } else if (toast.type === 'success') {
      return '/assets/svgs/notification-success.svg';
    } else if (toast.type === 'info') {
      return null;
    } else {
      return '/assets/svgs/notification-error.svg';
    }
  }
}
